<template>
  <div>
    <CookieMessage/>
    <!--<SaleBanner :text="'Tripening Winter Special: Use Code WINTER22 for 5% Off Your Attractions'"/>-->
    <v-app :class="{ 'dark': $store.state.isPopupGalleryOpened || $store.state.darkClass, 'is-open': $store.state.isPopupGalleryOpened, 'hotels-listing': (this.$route.name.indexOf('hotels-city') > -1) && (this.$route.name.indexOf('map') < 0), 'express-order': (this.$route.name.indexOf('express-order') > -1)  }">
      <site-header/>
      <nuxt/>
      <site-footer/>
    </v-app>
    <div class="global-loading" v-if="globalLoading"></div>
  </div>
</template>

<script>
import SiteHeader from '@/components/NewHeader/Header'
import SiteFooter from '@/components/Footer/Footer'
/*import SaleBanner from '@/components/Banners/SaleBanner/SaleBanner'*/
import CookieMessage from '@/components/CookieMessage/CookieMessage'
import LazyHydrate from 'vue-lazy-hydration'

const faviconURls = {
  tripening: '/tripening.ico',
  rsg: '/rsg.png',
}

const titles = {
  tripening: `Things to do, Tickets, Hotels and Flights | ${new Date().getFullYear()} | Tripening`,
  rsg: `Hotels | ${new Date().getFullYear()} | Resago`,
}

export default {
  head() {
    return {
      title: titles[this.$store.state.siteBrand],
      link: [
        {  rel: 'icon', type: 'image/x-icon', href: `${this.$config.baseURL}${faviconURls[this.$config.brand]}` },
        {
          rel: 'canonical',
          hid: 'canonical',
          href: (this.$route.query.page && !this.$route.params.item) ? `${this.$config.baseURL}${this.$route.path}?page=${parseFloat(this.$route.query.page)}` : `${this.$config.baseURL}${this.$route.path}`
        }
      ]
    }
  },
  components: {
    SiteHeader,
    SiteFooter,
    CookieMessage,
    LazyHydrate
    /*SaleBanner*/
  },
  computed: {
    globalLoading() {
      return this.$store.state.globalLoading
    }
  },
  mounted() {
    if (process.browser) {
      const isWebp = document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0
      this.$store.commit('SET_IS_WEBP', isWebp)
    }

    this.$store.dispatch('getCurrency')
  },
}
</script>
<style lang="scss">
  @import 'assets/css/normalize.css';
  @import 'assets/css/common.css';
  @import 'assets/css/result-pages.css';
  @import 'assets/scss/common.scss';
</style>
