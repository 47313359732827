export default {
  data() {
    return {
      isLoadingGallery: false,
      countImages: 0,
    }
  },
  methods: {
    resetLoading(count, mainLength) {
      // this.isLoadingGallery = count === mainLength / 2
      count === mainLength / 2 ? this.isLoadingGallery = true : this.isLoadingGallery = false
    },
    createCartImgObj(product, width = 60, height = 60) {
      if (!product && !product.photo_url) return
      return this.createImgObj(product.photo_url, width, height)
    },
    createResultImgObject(product, width, height) {
      const _requestedImgUrl = product.cover_image_url || product.images?.[0] || null
      return this.createImgObj(_requestedImgUrl, width, height)
    },
    createImgObj(requestedImgUrl, width, height) {
      if (!requestedImgUrl) return

      const _width = width || 191
      const _height = height || 250

      const imgUrl = this.imageUrlWithProxy(requestedImgUrl, _width, _height)

      return {
        src: imgUrl,
        error: '/img/no-photo.svg',
        loading: '/img/no-photo.svg'
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    imageUrlWithProxy(url, width, height, resizeType = 'fill') {
      const siteName = this.$config.siteName

      if (!siteName?.length) return url

      //TODO: check if proxy is enabled by env variable
      const isProxyEnabled = siteName.indexOf('Resago') === -1

      const isDev = (siteName.indexOf('dev') > -1) || (siteName.indexOf('stage') > -1)
      const imgproxyServer =  isDev ? 'imgproxy-' :  'imgproxy.'
      const proxyName = isDev ? siteName : siteName.replace(/www\./, '')

      let proxyHost = `https://${imgproxyServer}${proxyName}`

      return isProxyEnabled ? `${proxyHost}/insecure/${resizeType}/${width}/${height}/sm/0/plain/${url}@webp` : url
    },
    checkProxy(imgUrl) {
      return imgUrl ? (imgUrl.indexOf('tripening') !== -1) && (imgUrl.indexOf('https://') === -1) : false
    },
    getBase64ImgCode(base64FullStr) {
      return base64FullStr.replace(/^data:image\/[a-z]+;base64,/, '')
    }
  },
  destroyed() {
    this.countImages = 0
  }
}
